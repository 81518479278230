import React, { useEffect, useState } from "react";

import "./pagination.css";

const Pagination = ({ length, currentPage, setCurrentPage }) => {
  const [pages, setPages] = useState([]);
  const [lowerPage, setLowerPage] = useState(0);
  const [upperPage, setUpperPage] = useState(1);
  useEffect(() => {
    // console.log(length);
    const sz = Math.ceil(length / 5);
    // console.log(sz);
    let arr = [];
    if (sz <= 5) {
      setPages([...Array(sz).keys()])
    } else {
      if (currentPage < 5) {
        arr = [0, 1, 2, 3, 4, "...", sz - 1];
        setUpperPage(4);
      } else if (currentPage + 4 >= sz) {
        arr = [0, "...", ...Array.from({ length: 5 }, (_, i) => i + sz - 5)];
        setLowerPage(sz - 5);
      } else {
        arr = [
          0,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          sz - 1,
        ];
        setLowerPage(currentPage - 1);
        setUpperPage(currentPage + 1);
      }
    }
    setPages([...arr]);
  }, [currentPage, length]);

  const onClickHandler = (content, id) => {
    if (content === "...") {
      if (id === 1) {
        setCurrentPage(lowerPage - 1);
      } else setCurrentPage(upperPage + 1);
    } else {
      setCurrentPage(content);
    }
  }

  return (
    <div className="pagination-container">
      {pages.map((content, id) => (
        <button
          className={`pagination-dot ${
            content === "..."
              ? "pagination-dot-ellipsis"
              : currentPage === content
              ? "pagination-dot-active"
              : "pagination-dot-normal"
          }`}
          onClick={() => onClickHandler(content, id)}
        >
          {content === "..." ? content : content + 1}
        </button>
      ))}
    </div>
  )
}

export default Pagination;
