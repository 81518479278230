import React, { useState } from "react"
import moment from "moment"
import { navigate } from "gatsby"
import { Card, Button } from "react-bootstrap"
import SEO from "../components/seo"
import "./wpBlog.css"
import Pagination from "./pagination"

const WpBlog = ({ blogPreviews }) => {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <div className="blog-list">
      <SEO title="Blog" />
      <h3 className="blog-header text-primary">Blog</h3>
      <br />
      <br />
      <div className="blog-previews">
        {blogPreviews
          .slice(
            currentPage * 5,
            Math.min(blogPreviews.length, (currentPage + 1) * 5)
          )
          .map(
            ({
              title,
              excerpt,
              id,
              slug,
              date,
              categories: {
                nodes: {
                  0: { name: category },
                },
              },
            }) => {
              const newExcerpt = excerpt.split("Continue reading")[0]

              return (
                <div key={id}>
                  <Card className="blog-card">
                    <h6 className="text-primary blog-preview-title">{title}</h6>
                    <Card.Body
                      className="blog-excerpt"
                      dangerouslySetInnerHTML={{
                        __html: newExcerpt,
                      }}
                    ></Card.Body>

                    <Button
                      variant="secondary"
                      className="read-blog-button"
                      style={{ maxWidth: 300, marginleft: 40 }}
                      onClick={() => navigate(`/entry/${slug}`)}
                    >
                      Click Here To Read{" "}
                    </Button>
                    <br />
                    <i>Published: {moment(date).format("Do MMMM YYYY")}</i>
                    <span>By: Compare n Save</span>
                    <span>Categorised: {category} </span>
                  </Card>
                </div>
              )
            }
          )}

        <Pagination
          length={blogPreviews.length}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default WpBlog
